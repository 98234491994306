const teamPictures = [
  {
    photo: '/team/javier.png',
    linkedin: '',
  },
  {
    photo: '/team/jose.png',
    linkedin: 'https://www.linkedin.com/in/jose-manuel-gonzalez-aguirre-1518a726',
  },
  {
    photo: '/team/agil.png',
    linkedin: 'https://www.linkedin.com/in/antonio-gil-o-reilly-7ab607104',
  },
  {
    photo: '/team/vicente.png',
    linkedin: 'https://www.linkedin.com/in/vicente-errazuriz-dell-oro-159129b7',
  },
  {
    photo: '/team/chris.png',
    linkedin: 'https://www.linkedin.com/in/christian-teuber-401127148',
  },
  {
    photo: '/team/josefina_achurra.png',
    linkedin: 'https://www.linkedin.com/in/josefina-achurra-7163a5225/',
  },

  {
    photo: '/team/felipe.png',
    linkedin: 'https://www.linkedin.com/in/felipe-barr%C3%ADa-mora-25a837117',
  },
  {
    photo: '/team/diego.png',
    linkedin: 'https://www.linkedin.com/in/diego-varas-dom%C3%ADnguez-015804127',
  },
  {
    photo: '/team/rosas.png',
    linkedin: 'https://www.linkedin.com/in/jrrosasg',
  },
  {
    photo: '/team/isi.png',
    linkedin: 'https://www.linkedin.com/in/isidora-quevedo-32b400167/',
  },
  {
    photo: '/team/claudia.png',
    linkedin: '',
  },
  {
    photo: '/team/rocio.png',
    linkedin: 'https://www.linkedin.com/in/rocio-galvez-a59893169',
  },
  {
    photo: '/team/tatiana.png',
    linkedin: 'https://www.linkedin.com/in/tatiana-zu%C3%B1iga-5ab512184',
  },
  {
    photo: '/team/maria.png',
    linkedin: 'http://www.linkedin.com/in/maria-l%C3%B3pez-2bba64158',
  },
  {
    photo: '/team/andres.png',
    linkedin: 'https://www.linkedin.com/in/andr%C3%A9s-araya-meneses-916a1675',
  },
  {
    photo: '/team/tamara.png',
    linkedin: '',
  },
  {
    photo: '/team/thomas.png',
    linkedin: 'https://www.linkedin.com/in/thomas-wenderoth-hucke-9b9200168/',
  },
  {
    photo: '/team/raimundo_orellana.png',
    linkedin: 'https://www.linkedin.com/in/rvorellana/',
  },
  {
    photo: '/team/juan_silva.png',
    linkedin: 'https://www.linkedin.com/in/juan-ignacio-silva-mc-intyre-20b153196/',
  },
  {
    photo: '/team/domingo_valdes.png',
    linkedin: 'https://www.linkedin.com/in/domingo-vald%C3%A9s-ossa-3b8685111/',
  },
  {
    photo: '/team/rodrigo_reyes.png',
    linkedin: 'https://www.linkedin.com/in/rodrigo-reyes-villalobos-78446449/',
  },
  {
    photo: '/team/anita_valenzuela.png',
    linkedin: 'https://www.linkedin.com/in/anita-valenzuela-904b71282/',
  },
  {
    photo: '/team/denisse_vergara.png',
    linkedin: 'https://www.linkedin.com/in/denisse-vergara-castro/',
  },
  {
    photo: '/team/vicente_molin.png',
    linkedin: 'https://www.linkedin.com/in/vamolin/',
  },
  {
    photo: '/team/mathias_valdebenito.png',
    linkedin: 'https://www.linkedin.com/in/mlvaldebenito/',
  },
  {
    photo: '/team/juan_carlos_curihual.png',
    linkedin: 'https://www.linkedin.com/in/juan-carlos-curihual-navarrete-5a520428/',
  },
  {
    photo: '/team/betsabet_quezada.png',
    linkedin: 'https://www.linkedin.com/in/betsabet-quezada-alvarez-358447a0/',
  },
  {
    photo: '/team/nycole_munoz.png',
    linkedin: 'https://www.linkedin.com/in/nycole-francisca-mu%C3%B1oz-esparza-179ab81b0/',
  },
  {
    photo: '/team/agustina_vasquez.png',
    linkedin: 'https://www.linkedin.com/in/agustina-vasquez-fuxs-5412a91b1/',
  },
  {
    photo: '/team/alberto_lamar.png',
    linkedin: 'https://www.linkedin.com/in/alberto-lamar-parra-668a00ba/',
  },
  {
    photo: '/team/andres_lopez.png',
    linkedin: 'https://www.linkedin.com/in/andr%C3%A9s-ignacio-l%C3%B3pez-mu%C3%B1oz-97a031253/',
  },
  {
    photo: '/team/benjamin_martinez.png',
    linkedin: 'https://www.linkedin.com/in/benjam%C3%ADn-mart%C3%ADnez-1bb892164/',
  },
  {
    photo: '/team/claudio_barahona.png',
    linkedin: 'https://www.linkedin.com/in/claudio-barahona-r/',
  },
  {
    photo: '/team/diego_blanco.png',
    linkedin: 'https://www.linkedin.com/in/diegoblancoayarza/',
  },
  {
    photo: '/team/francisco_mena.png',
    linkedin: 'https://www.linkedin.com/in/fmenah/',
  },
  {
    photo: '/team/juan_pablo_montalva.png',
    linkedin: 'https://www.linkedin.com/in/juanpablo-montalvav/',
  },
  {
    photo: '/team/yanara_campos.png',
    linkedin: 'https://www.linkedin.com/in/yanara-campos-06b846275/',
  },
  {
    photo: '/team/javiera_irrarrazaval.png',
    linkedin: 'https://www.linkedin.com/in/javiera-irarr%C3%A1zaval-greene-7105a71a6/',
  },
  {
    photo: '/team/catalina_gomez.png',
    linkedin: 'https://www.linkedin.com/in/catalina-g%C3%B3mez-berr%C3%ADos-89599120a/',
  },
  {
    photo: '/team/valentina_perez.png',
    linkedin: 'https://www.linkedin.com/in/valentina-p%C3%A9rez-figueroa-a77923256/',
  },
  {
    photo: '/team/patricio_lecaros.png',
    linkedin: 'https://www.linkedin.com/in/patricio-lecaros-vial-b1a38b258/',
  },
  {
    photo: '/team/carla_cardenas.png',
    linkedin: 'https://www.linkedin.com/in/carla-c%C3%A1rdenas-correa-14765ab9/',
  },
  {
    photo: '/team/romina_castillo.png',
    linkedin: 'https://www.linkedin.com/in/romina-castillo-mu%C3%B1oz-7391b3234/',
  },
  {
    photo: '/team/javi_lazcano.png',
    linkedin: 'https://www.linkedin.com/in/javiera-lazcano/',
  },
  {
    photo: '/team/fernanda_molina.png',
    linkedin: 'https://www.linkedin.com/in/fernanda-molina-monroy-053b19141/',
  },
  {
    photo: '/team/leticia_perez.png',
    linkedin: 'https://www.linkedin.com/in/leticia-p%C3%A9rez-palma-116830209/',
  },
  {
    photo: '/team/luz_maria_guidi.png',
    linkedin: 'https://www.linkedin.com/in/luzmariaguridi/',
  },

];

export default teamPictures;
